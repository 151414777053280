import React, { useState, useEffect } from "react";
import axios from "axios";
import "./style/App.scss";

// Components
import DesktopNav from "./components/navbar/desktop-nav";
import MobileNav from "./components/navbar/mobile-nav";
import Backdrop from "./components/navbar/backdrop";
import Hero from "./components/hero/hero";
import Portfolio from "./components/portfolio/portfolio";
import About from "./components/about/about";
import Contact from "./components/contact/contact";
import Footer from "./components/footer/footer";

const App = () => {
  const [isScrolled, setUserIsScrolled] = useState(false);
  const [mobileNavbarOpen, setmobileNavbarOpen] = useState(false);
  const [bio, setBio] = useState("");

  const userIsScrolled = () => {
    if (window.pageYOffset > 80) {
      setUserIsScrolled(true);
    } else {
      setUserIsScrolled(false);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      const resBio = await axios("https://api.roto-toba.com/api/get-bio");
      setBio(resBio.data);
    };
    fetchData();
  }, []);

  // console.log(bio);
  // useEffect(() => {
  //   window.addEventListener("scroll", userIsScrolled);
  // }, [userIsScrolled]);

  // On closeMobileMenu click close navbar
  const closeMobileMenu = () => {
    setmobileNavbarOpen(false);
  };
  // Mobile menu handler
  const mobileMenuOpen = () => {
    setmobileNavbarOpen(true);
  };

  // BACKDROP RENDER
  let backdrop = <Backdrop closeMobileMenu={closeMobileMenu} />;
  if (mobileNavbarOpen) {
    backdrop = <Backdrop closeMobileMenu={closeMobileMenu} isOpen={true} />;
  }
  // MOBILE NAVBAR RENDER
  let mobileNavbar = <MobileNav />;
  if (mobileNavbarOpen) {
    mobileNavbar = (
      <MobileNav isOpen={true} closeMobileMenu={closeMobileMenu} />
    );
  }

  return (
    <div className="App">
      {mobileNavbar}
      {backdrop}
      <DesktopNav
        userIsScrolled={userIsScrolled}
        mobileMenuOpen={mobileMenuOpen}
      />
      <Hero data={bio} />
      <Portfolio />
      <About />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;
