import React from "react";
import { Row, Col } from "react-flexbox-grid";
import "./about.scss";
// Components
import TeamBox from './teamBox';
import TeamInfo from "./teamInfo";
import Title from "../ui-components/title/title";
// Assets
import Drupal from "../../assets/about/drupal.svg";
import Docker from "../../assets/about/docker-3.svg";
import Serverless from "../../assets/about/serverless-icon.svg";

const about = () => (
  <div id="about">
    <div className="wrapper">
      <Title title="MY EXPERTISE." />
      <p className="font12">
        These are the main technologies that I have been using since my web development career started:
      </p>
      <Row>
        <Col className="about-box" md={12} lg={2}>
          <TeamBox avatar="https://upload.wikimedia.org/wikipedia/commons/a/a7/React-icon.svg" name="" job="" />
        </Col>
        <Col className="about-box" md={12} lg={2}>
          <TeamBox avatar="https://www.php.net/images/logos/php-logo.svg" name="" job="" />
        </Col>
        <Col className="about-box" md={12} lg={2}>
          <TeamBox avatar={Docker} name="" job="" />
        </Col>
        <Col className="about-box" md={12} lg={2}>
          <TeamBox avatar={Serverless} name="" job="" />
        </Col>
        <Col className="about-box"  md={12} lg={2}>
          <TeamBox avatar={Drupal} name="" job="" />
        </Col>
      </Row>
    </div>
  </div>
);

export default about;
