import React from "react";
// Scss
import "./projectBox.scss";

const projectBox = (props) => (
  <div className="portfolio__box" onClick={() => window.open(props.link, '_blank')}>
    <img src={props.preview} alt="project" />
    <div className="portfolio__hover-info flex-center" on>
      <div className="text-center">
        <p className="font30 weight800">{props.title}</p>
        <hr style={{border: '1px solid black', maxWidth: '200px'}}/>
        <p className="font12 weight500">{props.tag}</p>
      </div>
    </div>
  </div>
);

export default projectBox;
